<template>
    <div class="om_collect_list_wrap">
        <ul class="item_card_wrap">
            <li class="list_item" v-for="(data,index) in itemData" :key="`item${index}`">
                <div class="thumbnail" :style="` background-image: url(${returnThumbnail(data)});`" @click="moveDetail(data.i_sku)">
                    <div class="card_category cc_category">{{ data.ic_name_eng }}</div>
                    <div class="card_category cc_real" v-if="data.i_type ==='real'">{{ $t('real') }}</div>
                    <div class="card_category cc_real play_icon" v-if="data.im_type === 'video'|| data.im_type==='audio'" >
                      <img class="icon play" src="@/assets/image/main/icon_play.png" alt="">
                    </div>
                </div>
                <div class="card_detail">
                    <div class="profile_group">
<!--                        컬렉션-->
                        <el-popover
                                trigger="click"
                                :open-delay="500"
                                :offset="0"
                                :append-to-body="false"
                                :popper-options="{
                                boundariesElement: 'body',
                                gpuAcceleration: true,
                                positionFixed: true,
                                preventOverflow: true,}"
                                popper-class="main_popover no_profile_popover"
                        >
                            <img slot="reference" class="card_profile" :src="returnCollectImage(data)">
                            <div class="popover_wrap" v-if="checkCollect(data)"
                                 @click="moveCollect(data)">
                                <div class="popover_top">
                                    <div class="popover_creator">
                                        {{ $t('collection') }}
                                    </div>
                                    <div class="popover_nickname no_profile_nickname" style="height: 95px">
                                        <img class="nickname_img collection_img" :src="returnCollectImage(data)">
                                        <div class="popover_text_list collect_text_info">
                                            <div class="popover_nickname_list">
                                                <div class="nick_text">{{ data.Collection.c_title }}</div>
                                            </div>
                                            <div class="popover_nickname2">
                                                <img class="pn_img" src="@/assets/image/main/heart1.png">
                                                <div>{{ returnCollectLike(data.Collection) }}</div>
                                                <div>ITEM</div>
                                                <div>{{ data.Collection.item_cnt }}</div>
                                            </div>
                                            <div class="popover_price">
                                                <span class="price">{{ returnPriceString(data.Collection.c_total) }} Mg </span>
                                                <span class="item_small">({{ returnPriceStringMingle(data.Collection.c_total) }})</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="popover_bottom">
                                    <div>
                                        {{ data.Collection.c_intro }}
                                    </div>
                                </div>
                            </div>
                        </el-popover>
                        <el-popover
                                trigger="click"
                                @show="getMemberData(data,'im_originator')"
                                :open-delay="500"
                                :offset="0"
                                :append-to-body="false"
                                :popper-options="{
                                boundariesElement: 'body',
                                gpuAcceleration: true,
                                positionFixed: true,
                                preventOverflow: true,}"
                                popper-class="main_popover no_profile_popover"
                        >
                            <img slot="reference" class="card_profile" :src="returnItemProfile(data,'originator')">
                            <div class="popover_wrap" v-if="checkMemberData()"
                                 @click="moveMember(`creator/${memberData.mb_uuid}`)">
                                <div class="popover_top">
                                    <div class="popover_creator">
                                        {{ $t('k_creator') }}
                                    </div>
                                    <div class="popover_nickname">
                                        <img class="nickname_img" :src="returnMemberImg()">
                                        <div class="popover_text_list">
                                            <div class="popover_nickname_list">
                                                <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                    <span>Curator</span></div>
                                                <div class="pop_curator second_color" v-if="memberData.mb_premium===1">
                                                    <span>Special</span></div>
                                            </div>
                                            <div class="popover_nickname2">
                                                <img class="pn_img" src="@/assets/image/main/heart1.png">
                                                <div>{{ memberData.likeCnt }}</div>
                                                <div>ITEM</div>
                                                <div>{{ memberData.itemCnt }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="popover_bottom">
                                    <div>
                                        {{ memberData.mb_introduce }}
                                    </div>
                                </div>
                            </div>
                            <div class="popover_wrap" v-else>
                                <div class="popover_top no_profile">
                                    <div class="popover_creator no_profile_creator">
                                        {{ $t('k_creator') }}
                                    </div>
                                    <div class="popover_nickname no_profile_nickname">
                                        <img class="nickname_img no_profile_img"
                                             src="@/assets/image/main/no_profile.png">
                                        <div class="popover_text_list no_profile_text">
                                            <div class="popover_nickname_list">
                                                <div class="nick_text no_profile_text">{{ $t('notInfo') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="popover_bottom no_profile_bottom">
                                    <div class="pop_pover_div no_profile_div">
                                        {{ $t('notProvider') }}
                                    </div>
                                </div>
                            </div>
                        </el-popover>

                        <el-popover
                                placement="bottom"
                                trigger="click"
                                @show="getMemberData(data,'provider')"
                                :open-delay="500"
                                :offset="0"
                                :append-to-body="false"
                                :popper-options="{
                                boundariesElement: 'body',
                                gpuAcceleration: true,
                                positionFixed: true,
                                preventOverflow: true,}"
                                popper-class="main_popover no_profile_popover">
                            <img slot="reference" class="card_profile" :src="returnItemProfile(data,'provider')">
                            <div class="popover_wrap" v-if="checkMemberData()"
                                 @click="moveMember(`creator/${memberData.mb_uuid}`)">
                                <div class="popover_top">
                                    <div class="popover_creator">
                                        {{ $t('owner') }}
                                    </div>
                                    <div class="popover_nickname">
                                        <img class="nickname_img" :src="returnMemberImg()">
                                        <div class="popover_text_list">
                                            <div class="popover_nickname_list">
                                                <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                    <span>Curator</span></div>
                                                <div class="pop_curator second_color" v-if="memberData.mb_premium===1">
                                                    <span>Special</span></div>
                                            </div>
                                            <div class="popover_nickname2">
                                                <img class="pn_img" src="@/assets/image/main/heart1.png">
                                                <div>{{ memberData.likeCnt }}</div>
                                                <div>ITEM</div>
                                                <div>{{ memberData.itemCnt }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="popover_bottom">
                                    <div>
                                        {{ memberData.mb_introduce }}
                                    </div>
                                </div>
                            </div>
                        </el-popover>


                        <el-popover
                                placement="bottom"
                                trigger="click"
                                @show="getMemberData(data,'ibc_curator')"
                                :open-delay="500"
                                :offset="0"
                                :append-to-body="false"
                                :popper-options="{
                                boundariesElement: 'body',
                                gpuAcceleration: true,
                                positionFixed: true,
                                preventOverflow: true,}"
                                popper-class="main_popover no_profile_popover">
                            <img slot="reference" class="card_profile" v-if="checkCurator(data)"
                                 :src="returnItemProfile(data,'curator')">
                            <div class="popover_wrap" @click="moveMember(`creator/${memberData.mb_uuid}`)">
                                <div class="popover_top">
                                    <div class="popover_creator">
                                        {{ $t('curator') }}
                                    </div>
                                    <div class="popover_nickname">
                                        <img class="nickname_img" :src="returnMemberImg()">
                                        <div class="popover_text_list">
                                            <div class="popover_nickname_list">
                                                <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                    <span>Curator</span></div>
                                                <div class="pop_curator second_color" v-if="memberData.mb_premium===1">
                                                    <span>Special</span></div>
                                            </div>
                                            <div class="popover_nickname2">
                                                <img class="pn_img" src="@/assets/image/main/heart1.png">
                                                <div>{{ memberData.likeCnt }}</div>
                                                <div>ITEM</div>
                                                <div>{{ memberData.itemCnt }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="popover_bottom">
                                    <div>
                                        {{ memberData.mb_introduce }}
                                    </div>
                                </div>
                            </div>
                        </el-popover>


                    </div>
                    <div class="icon_group" >
                        <div class="icon_wrap pr-10" >
                            <div class="like_img" :class="{'active': isMyLike(data)}" @click="likeItem(data.i_idx)"></div>
                            <div class="icon_count" @click="likeItem(data.i_idx)">{{ data.im_like }}</div>
                        </div>
                        <div class="icon_wrap" @click="moveDetail(data.i_sku)">
                            <img class="icon eye" src="@/assets/image/main/eye_empty.png" alt="">
                            <div class="icon_count">{{ data.im_real_hit }}</div>
                        </div>
                    </div>
                    <div class="card_title card_title_1" @click="moveDetail(data.i_sku)">{{ data.i_name }}</div>
                    <div class="item_status time" @click="moveDetail(data.i_sku)">{{ returnItemType(data) }}</div>
                    <div class="item_price" @click="moveDetail(data.i_sku)">
<!--                        <img class="item_small_logo" src="@/assets/image/main/small_logo.png" alt="">-->
                        <span class="price">{{ returnCommas(data.i_price) }} Mg</span></br>
                        <span class="item_small">({{returnMingle(data.i_price)}})</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import {mapState} from "vuex";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import EventBus from "@/utils/event-bus";
import priceMixins from "@/mixins/priceMixins";

let timeInterval = null;
let getItemInterval = null;
export default {
    name: "ItemListOpenMarketLayout",
    mixins: [imageOption, dateMixins, imageResizeMixins,priceMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            memberCheck: false,
            currentTime: '',
            memberData: {},

            isGetData: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getCurrentTime();
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
        getItemInterval = setInterval(() => {
            EventBus.$emit('getItemList')
        }, 1000 * 60)
    },
    beforeDestroy() {
        clearInterval(timeInterval)
        clearInterval(getItemInterval)
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
      returnCommas(x = 0) {
        //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          if(isNaN(x)) {
              return 0;
          }
          return util.Number.numFormat(x);
      },
        moveDetail(data) {
            this.$router.push(`/openmarket/${data}`)
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, null, null)}`;
                // return `${data.ItemFile.Item[0].org_url}?w=278?f=png`;
                return this.setFitWidthImg(data.ItemFile.Item, 278);
            }
        },
        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(40, 40, 'png')}`;
            }
            return 'http://testimage.minglemint.com/profile/no_image.png?w=40&h=40&f=png'
        },


        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },
        getMemberData(data, type) {
            this.memberData = {}
            if (util.isEmpty(data[type])) {
                return
            }
            let params = {
                no: data[type]
            }
            this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || util.isEmpty(res.List)) {
                    this.createAlert({
                        content: this.$t('detect_detail_empty'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                    return false;
                }
                this.memberData = res.List[0];
                this.$forceUpdate()
            })

        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(62, 62, 'png')}`
            }
        },
        checkMemberData() {
            return !util.isEmpty(this.memberData)
        },
        moveMember(url) {
            this.$router.push(`/${url}`)
        },
        returnItemType(data) {
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            if (data.i_status === 'auction') {
                if (data.ia_auction === 2) {
                    let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
                    return diffTime;
                } else {
                    return this.$t('beforeAuction');
                }

            } else if (data.i_status === 'license') {
                return this.$t('license')
            } else if (data.i_status === 'pricetag') {
                return this.$t('fix_selling')
            } else {
              return ''
                // return this.$t('no_sell')
            }
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            this.$forceUpdate();
        },
        isMyLike(data) {
            return data?.Like?.myLike
        },
        likeItem(key){
          EventBus.$emit('likeItem',key);
        },
        returnCollectImage(data) {
            if (!util.isEmpty(data.Collection) && !util.isEmpty(data.Collection.CollectionFiles) && !util.isEmpty(data.Collection.CollectionFiles.MainFile)) {
                return `${data.Collection.CollectionFiles.MainFile[0].org_url}?${this.setImageOptions(90, 90, 'webp')}`;
            }
        },
        checkCollect(data) {
            return !util.isEmpty(data.Collection)
        },
        moveCollect(data) {
            if (!util.isEmpty(data.Collection)) {
                this.$router.push(`/collect/${data.Collection.c_idx}`)
            }
        },
        returnCollectLike(data) {
            if (!util.isEmpty(data.Likes)) {
                return data.Likes.cnt
            }
            return 0
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
